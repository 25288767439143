import isEmpty from "../validation/is-empty";

import {
  SET_CURRENT_USER,
  UPLOADING_FILE,
  SIDEBAR,
  CHANGE_AVATAR,
  CONFIRM_EMAIL,
  ISNOT_UPLOADING,
  SENT_EMAIL,
  UPDATED_PASSWORD,
  GET_INVITATIONINFO,
  SENT_RECOVERYEMAIL,
  CONFIRM_TOKEN,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  isUploading: false,
  profilePicture: "defaultUser.jpg",
  isCollapsed: false,
  isConfirmed: false,
  isEmailSent: false,
  user: {},
  infoEmail: {},
  updated: true,
  invitation: {},
  recoveryEmail: false,
  confirmationToken: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case UPLOADING_FILE:
      return { ...state, isUploading: true };
    case SIDEBAR:
      return { ...state, isCollapsed: !state.isCollapsed };
    case CHANGE_AVATAR:
      return { ...state, profilePicture: action.payload, isUploading: false };
    case CONFIRM_EMAIL:
      return { ...state, isUploading: false, isConfirmed: true };
    case ISNOT_UPLOADING:
      return { ...state, isUploading: false };
    case UPDATED_PASSWORD:
      return { ...state, updated: action.payload };
    case CONFIRM_TOKEN:
      return { ...state, confirmationToken: action.payload };
    case GET_INVITATIONINFO:
      return { ...state, invitation: action.payload };
    case SENT_RECOVERYEMAIL:
      return { ...state, recoveryEmail: action.payload };

    case SENT_EMAIL:
      return {
        ...state,
        isUploading: false,
        isEmailSent: true,
        infoEmail: action.payload,
      };
    default:
      return state;
  }
}

import {
  ADD_CONTACTO,
  ADD_LEAD,
  GET_BUSQUEDA,
  GET_CAPITULOS,
  GET_SECCIONES,
  GET_PARTIDAS,
  GET_SUBPARTIDAS,
  GET_FRACCIONES,
  GET_NICOS,
  GET_NOTAS,
  GET_DESCRIPCION,
  GET_HISTORICO,
  GET_TLCS,
  GET_ALADIS,
  GET_FRANJA,
  GET_PROHIBICIONES,
  GET_INDICADORES,
  GET_ARANCEL_PAIS,
  GET_PERMISOS,
  GET_NOMS,
  GET_CUOTAS_COMPENSATORIAS,
  GET_IMMEX,
  GET_PROMOCION_SECTORIAL,
  GET_PRECIOS_ESTIMADOS,
  GET_ARANCEL_CUPO,
  GET_IVA,
  GET_ISAN,
  GET_IEPS,
  GET_PACIC,
  GET_MERCANCIA_PELIGROSA,
  GET_CONTROL_DE_EXPORTACIONES,
  GET_MEDIDAS_DE_TRANSICION,
  GET_DEPOSITO_FISCAL,
  GET_MERCANCIAS_VULNERABLES,
  GET_VEHICULOS_USADOS,
  GET_MARCAS_COMERCIALES,
  GET_ANEXO_7,
  GET_ANEXO_8,
  GET_ANEXO_9,
  GET_ANEXO_29,
  GET_ANEXO_10,
  GET_ANEXO_12,
  GET_ANEXO_14,
  GET_ANEXO_17,
  GET_ANEXO_21,
  GET_ANEXO_28,
  GET_ANEXO_21_DOS,
  GET_ANEXO_21_TRES,
  GET_ISAN_DOS,
  GET_PRECIOS_ESTIMADOS_DOS,
  GET_PRECIOS_ESTIMADOS_TRES,
  GET_CUPOS_TLC,
  GET_NOTAS_TLC,
  GET_CUPOS_ALADIS_UNO,
  GET_CUPOS_ALADIS_DOS,
  GET_CORRELATIVO,
  GET_INDICADORES_MANANA,
} from "../actions/types";

const initialState = {
  contacto: {},
  lead: {},
  busqueda: [],
  correlativo: [],
  secciones: [], // 0
  capitulos: [], // 2
  partidas: [], // 4
  subpartidas: [], // 6
  fracciones: [], // 8
  nicos: [], // 10
  notas: [],
  descripcion: [],
  historico: [],
  tlcs: [],
  aladis: [],
  franja: [],
  prohibiciones: [],
  indicadores: {},
  indicadoresDos: {},
  arancel: [],
  permisos: [],
  noms: [],
  cuotasCompensatorias: [],
  immex: [],
  promocionSectorial: [],
  preciosEstimados: [],
  precios_estimados_dos: [],
  precios_estimados_tres: [],
  arancelCupo: [],
  iva: [],
  isan: [],
  isan_dos: [],
  ieps: [],
  pacic: [],
  mercancia_peligrosa: [],
  control_de_exportaciones: [],
  medidas_de_transicion: [],
  deposito_fiscal: [],
  mercancias_vulnerables: [],
  vehiculos_usados: [],
  marcas_comerciales: [],
  anexo_7: [],
  anexo_8: [],
  anexo_9: [],
  anexo_29: [],
  anexo_10: [],
  anexo_12: [],
  anexo_14: [],
  anexo_17: [],
  anexo_21: [],
  anexo_21_dos: [],
  anexo_21_tres: [],
  anexo_28: [],
  cupos_tlc: [],
  notas_tlc: [],
  cupos_aladis_uno: [],
  cupos_aladis_dos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACTO:
      return { ...state, contacto: action.payload };

    case ADD_LEAD:
      return { ...state, lead: action.payload };

    case GET_BUSQUEDA:
      return { ...state, busqueda: action.payload };
    case GET_CORRELATIVO:
      return {
        ...state,
        correlativo: action.payload,
        busqueda: action.payload,
      };
    case GET_CAPITULOS:
      return { ...state, capitulos: action.payload };

    case GET_SECCIONES:
      return { ...state, secciones: action.payload };

    case GET_DESCRIPCION:
      return { ...state, descripcion: action.payload };
    case GET_INDICADORES:
      return { ...state, indicadores: action.payload };
    case GET_INDICADORES_MANANA:
      return { ...state, indicadoresDos: action.payload };
    case GET_PARTIDAS:
      return {
        ...state,
        partidas: state.partidas.concat(action.payload),
      };

    case GET_SUBPARTIDAS:
      return {
        ...state,
        subpartidas: state.subpartidas.concat(action.payload),
      };
    case GET_HISTORICO:
      return {
        ...state,
        historico: action.payload,
      };
    case GET_TLCS:
      return {
        ...state,
        tlcs: action.payload,
      };
    case GET_ALADIS:
      return {
        ...state,
        aladis: action.payload,
      };
    case GET_PROHIBICIONES:
      return {
        ...state,
        prohibiciones: action.payload,
      };

    case GET_FRANJA:
      return {
        ...state,
        franja: action.payload,
      };
    case GET_FRACCIONES:
      return {
        ...state,
        fracciones: state.fracciones.concat(action.payload),
      };
    case GET_NICOS:
      return {
        ...state,
        nicos: state.nicos.concat(action.payload),
      };
    case GET_NOTAS:
      return {
        ...state,
        notas: state.notas.concat(action.payload),
      };

    case GET_ARANCEL_PAIS:
      return {
        ...state,
        arancel: action.payload,
      };
    case GET_PERMISOS:
      return {
        ...state,
        permisos: action.payload,
      };
    case GET_NOMS:
      return {
        ...state,
        noms: action.payload,
      };
    case GET_CUOTAS_COMPENSATORIAS:
      return {
        ...state,
        cuotasCompensatorias: action.payload,
      };
    case GET_IMMEX:
      return {
        ...state,
        immex: action.payload,
      };
    case GET_PROMOCION_SECTORIAL:
      return {
        ...state,
        promocionSectorial: action.payload,
      };
    case GET_PRECIOS_ESTIMADOS:
      return {
        ...state,
        preciosEstimados: action.payload,
      };
    case GET_ARANCEL_CUPO:
      return {
        ...state,
        arancelCupo: action.payload,
      };
    case GET_IVA:
      return {
        ...state,
        iva: action.payload,
      };
    case GET_ISAN:
      return {
        ...state,
        isan: action.payload,
      };
    case GET_IEPS:
      return {
        ...state,
        ieps: action.payload,
      };
    case GET_PACIC:
      return {
        ...state,
        pacic: action.payload,
      };

    case GET_MERCANCIA_PELIGROSA:
      return { ...state, mercancia_peligrosa: action.payload };
    case GET_CONTROL_DE_EXPORTACIONES:
      return { ...state, control_de_exportaciones: action.payload };
    case GET_MEDIDAS_DE_TRANSICION:
      return { ...state, medidas_de_transicion: action.payload };
    case GET_DEPOSITO_FISCAL:
      return { ...state, deposito_fiscal: action.payload };
    case GET_MERCANCIAS_VULNERABLES:
      return { ...state, mercancias_vulnerables: action.payload };
    case GET_VEHICULOS_USADOS:
      return { ...state, vehiculos_usados: action.payload };
    case GET_MARCAS_COMERCIALES:
      return { ...state, marcas_comerciales: action.payload };
    case GET_ANEXO_7:
      return { ...state, anexo_7: action.payload };
    case GET_ANEXO_8:
      return { ...state, anexo_8: action.payload };
    case GET_ANEXO_9:
      return { ...state, anexo_9: action.payload };
    case GET_ANEXO_29:
      return { ...state, anexo_29: action.payload };
    case GET_ANEXO_10:
      return { ...state, anexo_10: action.payload };
    case GET_ANEXO_12:
      return { ...state, anexo_12: action.payload };
    case GET_ANEXO_14:
      return { ...state, anexo_14: action.payload };
    case GET_ANEXO_17:
      return { ...state, anexo_17: action.payload };
    case GET_ANEXO_21:
      return { ...state, anexo_21: action.payload };
    case GET_ANEXO_28:
      return { ...state, anexo_28: action.payload };

    case GET_ANEXO_21_DOS:
      return { ...state, anexo_21_dos: action.payload };
    case GET_ANEXO_21_TRES:
      return { ...state, anexo_21_tres: action.payload };
    case GET_ISAN_DOS:
      return { ...state, isan_dos: action.payload };
    case GET_PRECIOS_ESTIMADOS_DOS:
      return { ...state, precios_estimados_dos: action.payload };
    case GET_PRECIOS_ESTIMADOS_TRES:
      return { ...state, precios_estimados_tres: action.payload };

    case GET_NOTAS_TLC:
      return { ...state, notas_tlc: action.payload };

    case GET_CUPOS_TLC:
      return { ...state, cupos_tlc: action.payload };

    case GET_CUPOS_ALADIS_UNO:
      return { ...state, cupos_aladis_uno: action.payload };
    case GET_CUPOS_ALADIS_DOS:
      return { ...state, cupos_aladis_dos: action.payload };

    default:
      return state;
  }
}

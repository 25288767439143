import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import landingReducer from "./landingReducer";

const appReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  landing: landingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPLOADING_FILE = "UPLOADING_FILE";
export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const ISNOT_UPLOADING = "ISNOT_UPLOADING";
export const SENT_EMAIL = "SENT_EMAIL";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATED_PASSWORD = "UPDATED_PASSWORD";
export const GET_INVITATIONINFO = "GET_INVITATIONINFO";
export const SENT_RECOVERYEMAIL = "SENT_RECOVERYEMAIL";
export const CONFIRM_TOKEN = "CONFIRM_TOKEN";
export const SIDEBAR = "SIDEBAR";
export const ADD_CONTACTO = "ADD_CONTACTO";
export const ADD_LEAD = "ADD_LEAD";
export const GET_BUSQUEDA = "GET_BUSQUEDA";
export const GET_SECCIONES = "GET_SECCIONES";
export const GET_CAPITULOS = "GET_CAPITULOS";
export const GET_PARTIDAS = "GET_PARTIDAS";
export const GET_SUBPARTIDAS = "GET_SUBPARTIDAS";
export const GET_FRACCIONES = "GET_FRACCIONES";
export const GET_NICOS = "GET_NICOS";
export const GET_NOTAS = "GET_NOTAS";
export const GET_DESCRIPCION = "GET_DESCRIPCION";
export const GET_HISTORICO = "GET_HISTORICO";
export const GET_TLCS = "GET_TLCS";
export const GET_ALADIS = "GET_ALADIS";
export const GET_FRANJA = "GET_FRANJA";
export const GET_PROHIBICIONES = "GET_PROHIBICIONES";
export const GET_INDICADORES = "GET_INDICADORES";
export const GET_ARANCEL_PAIS = "GET_ARANCEL_PAIS";
export const GET_PERMISOS = "GET_PERMISOS";
export const GET_NOMS = "GET_NOMS";
export const GET_CUOTAS_COMPENSATORIAS = "GET_CUOTAS_COMPENSATORIAS";
export const GET_IMMEX = "GET_IMMEX";
export const GET_PROMOCION_SECTORIAL = "GET_PROMOCION_SECTORIAL";
export const GET_PRECIOS_ESTIMADOS = "GET_PRECIOS_ESTIMADOS";
export const GET_ARANCEL_CUPO = "GET_ARANCEL_CUPO";
export const GET_IVA = "GET_IVA";
export const GET_ISAN = "GET_ISAN";
export const GET_IEPS = "GET_IEPS";
export const GET_PACIC = "GET_PACIC";
export const GET_MERCANCIA_PELIGROSA = "GET_MERCANCIA_PELIGROSA";
export const GET_CONTROL_DE_EXPORTACIONES = "GET_CONTROL_DE_EXPORTACIONES";
export const GET_MEDIDAS_DE_TRANSICION = "GET_MEDIDAS_DE_TRANSICION";
export const GET_DEPOSITO_FISCAL = "GET_DEPOSITO_FISCAL";
export const GET_MERCANCIAS_VULNERABLES = "GET_MERCANCIAS_VULNERABLES";
export const GET_VEHICULOS_USADOS = "GET_VEHICULOS_USADOS";
export const GET_MARCAS_COMERCIALES = "GET_MARCAS_COMERCIALES";
export const GET_ANEXO_7 = "GET_ANEXO_7";
export const GET_ANEXO_8 = "GET_ANEXO_8";
export const GET_ANEXO_9 = "GET_ANEXO_9";
export const GET_ANEXO_29 = "GET_ANEXO_29";
export const GET_ANEXO_10 = "GET_ANEXO_10";
export const GET_ANEXO_12 = "GET_ANEXO_12";
export const GET_ANEXO_14 = "GET_ANEXO_14";
export const GET_ANEXO_17 = "GET_ANEXO_17";
export const GET_ANEXO_21 = "GET_ANEXO_21";
export const GET_ANEXO_28 = "GET_ANEXO_28";
export const GET_ANEXO_21_DOS = "GET_ANEXO_21_DOS";
export const GET_ANEXO_21_TRES = "GET_ANEXO_21_TRES";
export const GET_ISAN_DOS = "GET_ISAN_DOS";
export const GET_PRECIOS_ESTIMADOS_DOS = "GET_PRECIOS_ESTIMADOS_DOS";
export const GET_PRECIOS_ESTIMADOS_TRES = "GET_PRECIOS_ESTIMADOS_TRES";
export const GET_CUPOS_TLC = "GET_CUPOS_TLC";
export const GET_NOTAS_TLC = "GET_NOTAS_TLC";
export const GET_CUPOS_ALADIS_UNO = "GET_CUPOS_ALADIS_UNO";
export const GET_CUPOS_ALADIS_DOS = "GET_CUPOS_ALADIS_DOS";
export const GET_CORRELATIVO = "GET_CORRELATIVO";
export const GET_INDICADORES_MANANA = "GET_INDICADORES_MANANA";

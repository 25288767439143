import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";
import setAuthToken from "./utils/setAuthToken";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import store from "./store";
import "./App.css";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.dark} />
          <Suspense fallback={<Fragment />}>
            <Routes>
              <Route index path="/c/*" element={<LoggedInComponent />} />
              <Route index path="/*" element={<LoggedOutComponent />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
